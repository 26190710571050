interface AdminCustomBlockedProps {
    blockedInfo: string
}

export const AdminCustomBlocked = ({
    blockedInfo,
}: AdminCustomBlockedProps) => {
    const data = JSON.parse(blockedInfo)
    const title = Object.keys(data)[0]
    const reason = Object.values(data)[0]

    return (
        <div>
            <div className="font-header-3">{title}</div>
            <div
                className="font-body-text pt-2"
                dangerouslySetInnerHTML={{
                    __html: `
                    <div>
                            <p>${reason}</p>
                    </div>
                    `,
                }}
            />
        </div>
    )
}

export default AdminCustomBlocked
